/* NAVIGATION */
.navigation {
    position: fixed;
    z-index: 100;
    top: 0;
    left: 0;
    width: 100%;

    @include flex-y(flex-start, center);

    box-sizing: border-box;
    padding: 1.2rem 1.8rem;

    @include breakpoint(medium) {
        @include flex-x(space-between, center, nowrap);
    }
}

.nav-logo {
    width: 6rem;
}

.menu {
    ul {
        @include flex-y(flex-start, center);

        display: none; //To Do
        padding: 0;
        margin: 0;

        @include breakpoint(medium) {
            @include flex-x(flex-start, center, nowrap);
        }
    }

    li {
        list-style-type: none;
        display: flex;
    }

    a {
        color: $white;
        padding: 1rem;
        margin: 0;

        @include lead(1.4);
    }
}

// CTA MENU
.menu ul li:last-child a {
    color: $black;
}

#navigation.jsAni .menu a,
#navigation.jsAni .nav-logo {
    // opacity: 0;
}