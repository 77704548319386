/* BUTTON */
.button, button {
    background: $black;
    border: none;
    cursor: pointer;
    padding: 1.05rem 1.5rem 1.15rem;

    @include flex-x(center, center, nowrap);

    @include title(1.2);

    color: $white;
    margin: 0 0 1rem 0;

    &.is-active {
        background-color: $dark;
    }

    &.-margin-top {
        margin-top: 2rem;
    }

    &.-min-width {
		@include breakpoint(medium) {
			        min-width: 13rem;
		}
    }

    &.-is-hidden {
        opacity: 0;
    }
}

.buttongroup {
    @include flex-x(space-between, flex-start, wrap);
}