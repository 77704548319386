/* FORM */
.form {
  box-sizing: border-box;
}

.field-group-line {
  box-sizing: border-box;
  width: 100%;

  @include flex-y(flex-start, stretch);

  @include breakpoint(medium) {
    @include flex-x(flex-start, stretch, nowrap);
  }
}

.field-group {
  box-sizing: border-box;
  width: 100%;

  @include flex-y(flex-start, flex-start);
}

label {
  text-align: left;
  width: 100%;
  margin-bottom: 0.5rem;

  &.-is-hidden {
    display: none;
  }
}

input {
  @include p(1);

  width: 100%;
  padding: 1rem 1rem;
  margin: 0 0 1rem 0;
  border: none;
  border-radius: 0;
  box-sizing: border-box;

  &:last-child {
    margin-right: 0;
  }
}

input.submit.button {
  min-width: 0;
  cursor: pointer;

  @include breakpoint(medium) {
    width: 7rem;
  }
}
