/* FEED */

// Team Image Slack Icon
.team-image {
  overflow: hidden;
  border-radius: 13px;
  box-shadow: 
  inset 0 2rem 3rem -1.5rem fade-out($white, 0.7),
  inset 0 -2rem 3rem -1.5rem fade-out($black, 0.85);
  display: flex;
  margin-bottom: 0.2rem;
}

.team-image img {
  width: 4rem;
  height: 4rem;
  position: relative;
  z-index: -1;
}

