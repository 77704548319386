/* MIXIN */
/* FONT SETTINGS */
@mixin title($font-size) {
  font-family: $font;
  font-size: $font-size + rem;
  font-weight: 600;
  line-height: 1.05;
}

@mixin bold {
  font-family: $font;
  font-weight: 600;
  line-height: 1;
}

@mixin lead($font-size) {
  font-family: $font;
  font-size: $font-size + rem;
  font-weight: 600;
  line-height: 1.1;
}

@mixin p($font-size) {
  font-family: $font;
  font-size: $font-size + rem;
  font-weight: 500;
  line-height: 1.35;
}

/* FLEX MIXINS */
@mixin flex-row {
  display: flex;
  flex-direction: row;
}

@mixin flex-column {
  display: flex;
  flex-direction: column;
}

@mixin flex-y($justify-content, $align-items) {
  @include flex-column;

  justify-content: $justify-content;
  align-items: $align-items;
}

@mixin flex-x($justify-content, $align-items, $flex-wrap) {
  @include flex-row;

  justify-content: $justify-content;
  align-items: $align-items;
  flex-wrap: $flex-wrap;
}

/* FLUID TYPE MIXIN */
@function strip-unit($value) {
  @return $value / ($value * 0 + 1);
}

@mixin fluid-type($min-vw, $max-vw, $min-font-size, $max-font-size) {
  $u1: unit($min-vw);
  $u2: unit($max-vw);
  $u3: unit($min-font-size);
  $u4: unit($max-font-size);

  @if $u1 == $u2 and $u1 == $u3 and $u1 == $u4 {
    & {
      font-size: $min-font-size;

      @media screen and (min-width: $min-vw) {
        font-size: calc(
          #{$min-font-size} + #{strip-unit($max-font-size - $min-font-size)} *
            ((100vw - #{$min-vw}) / #{strip-unit($max-vw - $min-vw)})
        );
      }

      @media screen and (min-width: $max-vw) {
        font-size: $max-font-size;
      }
    }
  }
}

/* MEDIA QUERIES MIXIN */
@mixin breakpoint($point) {
  @if $point == medium {
    @media (min-width: $medium) {
      @content;
    }
  } @else if $point == large {
    @media (min-width: $large) {
      @content;
    }
  } @else if $point == xlarge {
    @media (min-width: $xlarge) {
      @content;
    }
  } @else if $point == xxlarge {
    @media (min-width: $xxlarge) {
      @content;
    }
  } @else if $point == xxxlarge {
    @media (min-width: $xxxlarge) {
      @content;
    }
  }
}
