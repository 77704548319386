/* SVG */
/* Kurtli Logo */
.kurtlilogo path {
  fill: $black;

  //	stroke: $black;
  stroke: none;
  stroke-miterlimit: 10;
  stroke-linecap: round;
}

.kurtlilogo {
  &__k-u-r {
    stroke-width: 11;
  }

  &__k {
    stroke-width: 11;
  }

  &__k2 {
    stroke-width: 11;
  }

  &__k3 {
    stroke-width: 11;
  }

  &__u {
    stroke-width: 11;
  }

  &__r {
    stroke-width: 11;
  }

  &__t {
    stroke-width: 10.5;
  }

  &__t2 {
    stroke-width: 11;
  }

  &__l {
    stroke-width: 10.5;
  }

  &__i {
    stroke-width: 10.5;
  }

}

/* Kurtli Head Styling */
.kurtlihead {
  width: 100%;
  max-width: 18rem;
  transition: transform 0.1 ease;
  will-change: transform;

  &.-is-small {
    max-width: 5rem;
  }
}

.kurtlihead__hairback,
.kurtlihead__hairfront,
.kurtlihead__eye,
.kurtlihead__moustache,
.kurtlihead__mouth,
.kurtlihead__antennanob {
  fill: $black;
}

.kurtlihead__face,
.kurtlihead__chin,
.kurtlihead__ears,
.kurtlihead__nose,
.kurtlihead__eyelid {
  fill: $white;
}

.kurtlihead__mouthshadow {
  fill: $light;
}

.kurtlihead__glasses,
.kurtlihead__antennarays {
  stroke: $black;
  fill: none;
  stroke-width: 9;
}

.kurtlihead__glasses {
  stroke-width: 9;
}

.kurtlihead__antennaline {
  stroke: $black;
  stroke-width: 5;
  fill: none;
}

.kurtlihead__antennarays {
  stroke-width: 2;
}

/* Kurtli Animation */
#kurtli-head-svg,
.kurtlihead__antennarays path,
.kurtlihead__antennanob,
.kurtlihead__antennaline {
  opacity: 0;
}

// Slack Icon
.icon-slack {
  display: inline;
  height: 1.2em;
  width: 1.2em;
  transform: translateY(0.2em);
}

.slack-icon--button {
  display: inline;
  margin: -2rem -0.4rem -2rem -2rem;
  width: 4rem;
  // display: none;
  transform: translateY(0.1rem);
}
