/* CONTENT */
.content {
  max-width: 70rem;
  display: flex;
  flex-direction: column;
  position: relative;
  text-align: center;
}

.content__text {
  overflow: hidden;
  display: flex;
  flex-direction: column;
  margin-left: 0;

  @include breakpoint(medium) {
    margin-left: 0;
  }

  .title {
    order: 1;
    margin-left: 2rem;

    @include breakpoint(medium) {
      margin-left: 0;
    }
  }

  p {
    order: 3;
  }

  p strong {
    color: $primary;
  }

  .content__stats {
    order: 2;
    margin-left: 2rem;

    @include breakpoint(medium) {
      margin-left: 0;
    }
  }
}

.content__text::before {
  content: "";
  background: $middle;
  position: absolute;
  left: -1rem;
  top: 1.5rem;
  width: 50%;
  max-width: 30rem;
  height: 80%;
  z-index: -1;
  display: none;
}

.content__data {
  display: flex;
  flex-direction: column;
  padding: 2rem 0 2rem 2rem;

  &.none {
    display: none;
  }

  @include breakpoint(medium) {
    flex-direction: row;
    padding: 2rem 0;
  }

  &.none {
    display: none;
  }
}

section.-type-post:first-child .content__data {
  display: none;
}

.content__clip {
  flex: 1;
  position: relative;
}

.content__clip a {
  font-size: 1.1rem;
  line-height: 1.25;
  color: $white;
  font-weight: 500;
  margin-left: 3.6rem;
  margin-bottom: 1rem;
  display: block;
  padding-right: 1rem;

  &::before {
    content: url("../img/clip.svg");
    position: absolute;
    width: 2.8rem;
    left: 0;
    transform: translate(0, -0.5rem);
  }

  b {
    color: $primary;
  }
}

.content__links {
  flex: 1;
  display: flex;
  flex-direction: column;
  position: relative;

  p {
    width: 100%;
    display: flex;
    flex-direction: column;
  }
}

.content__links a {
  font-size: 1.1rem;
  line-height: 1.25;
  color: $white;
  font-weight: 500;
  margin-left: 3.6rem;
  hyphens: auto;

  &::before {
    content: url("../img/arrow.svg");
    position: absolute;
    left: 0;
    width: 2.2rem;
    margin-right: 0.5rem;
    transform: translate(0, 0.2rem);
  }

  & + a {
    margin-top: 1rem;
  }

  b {
    color: $primary;
  }
}

.content__stats {
  display: flex;
  padding: 1.2rem 0 0.8rem;

  &.none {
    display: none;
  }

  &.none {
    display: none;
  }
}
