// Message

.alert {
  color: $white;
  width: 100%;
  margin: 2rem 0;
  padding: 2rem;
  display: none;
}

.alert.error {
  background-color: $error;
  display: block;
}

.alert.success {
  background-color: $success;
  display: block;
}
