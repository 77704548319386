/* TYPOGRAPHY */
html {
  font-size: 16px;

  @include fluid-type($min_width, $max_width, $min_font, $max_font);
}

body {
  font-family: $font, arial, sans-serif;
  color: $black;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  @include bold;

  margin: 0 0 1rem 0;
  width: 100%;
}

h1 {
  @include title(2.2);
}

h2 {
  @include title(1.9);
}

h3 {
  @include title(1.6);
}

h4 {
  @include title(1.5);
}

h5 {
  @include title(1.3);
}

h6 {
  @include title(1);
}

.site-title {
  text-align: center;
  margin-bottom: 0.4rem;
  color: $white;
  @include title(2);

  @include breakpoint(large) {
    font-size: 2.4rem;
  }

  @include breakpoint(xlarge) {
    font-size: 2.8rem;
  }

  &.-is-hidden {
    opacity: 0;
  }
}

.site-description {
  text-align: center;
  width: 100%;
  opacity: 0;

  @include lead(1.5);

  @include breakpoint(large) {
    font-size: 1.6rem;
  }

  @include breakpoint(xlarge) {
    font-size: 1.8rem;
  }

  & strong {
    color: $white;
  }

  & + .button {
    margin-top: 1.5rem;
  }
}

.section-title {
  @include title(2.5);

  b {
    color: $white;
    font-weight: 600;
  }
}

.title {
  @include title(1.8);

  b {
    color: $primary;
    font-weight: 600;
  }
}

.subtitle {
  @include title(1.4);
  line-height: 1.15;
  b {
    color: $white;
    font-weight: 600;
  }
}

.lead {
  @include lead(1.6);

  @include breakpoint(xlarge) {
    font-size: 1.8rem;
  }

  b {
    color: $white;
    font-weight: 600;
  }
}

p {
  @include p(1);
  margin: 0;
  + p {
    margin-top: 0.5rem;
  }
}

.title + p {
  margin-top: 0.5rem;
}

.title + .lead {
  margin-top: 2rem;
}

.subtitle + .button {
  margin-top: 2rem;
}

a {
  text-decoration: none;
  color: $black;

  &.-is-bright {
    color: $white;
  }
}

.caption {
  @include p(0.8);

  &.-align-left {
    text-align: left;
  }
  &.-is-hidden {
    opacity: 0;
  }
}

.step {
  @include title(1.8);

  color: $white;
  margin-bottom: 0.3rem;
}

.separator {
  @include p(1.2);

  margin: 1rem 1.2rem 2rem;

  @include breakpoint(medium) {
    margin: 0.8rem 1.2rem;
  }
}

// Code
pre.embed-code {
  box-sizing: border-box;
  overflow: auto;
  max-width: 100%;
  font-size: 0.7rem;
  line-height: 1.2;
  text-align: left;
  background: darken($white, 5%);
  border-radius: 7px;
  padding: 0.8rem 1rem;
  border: 5px solid $middle;
  box-shadow: inset 0 0rem 0.5rem 0 fade-out($black, 0.7);
}