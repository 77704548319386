/* GALLERY & IMAGES */
.svg-keyvisual {
    fill: none;
    width: 20rem;
    height: 100%;
    top: 0;
    bottom: 0;
    display: block;
    position: absolute;
    z-index: -1;

    path,
    ellipse,
    line,
    circle {
        stroke: $primary;
        stroke-dasharray: 1000;
        stroke-dashoffset: 1000;
        animation: line 5s ease forwards 1s;
        stroke-linecap: round;
        stroke-linejoin: round;
    }

    &.none {
        display: none;
    }

    .st1 {
        stroke-width: 11;
    }

    .st2 {
        stroke-width: 8;
    }
}

.post-img {
    width: 15rem;
    height: 15rem;
    background-color: $light;
    object-fit: cover;
    object-position: center;
}

.gallery {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
}

.gallery__item {
    width: calc(100% / 3);

    @include breakpoint(medium) {
        width: calc(100% / 3);
    }

    @include breakpoint(large) {
        width: calc(100% / 4);
    }

    @include breakpoint(xlarge) {
        width: calc(100% / 5);
    }

    @include breakpoint(xxlarge) {
        width: calc(100% / 6);
    }
}


.labels {
    max-width: 6rem;
    margin: 0.5rem 0;
}