/* CARD */
.card {
    @include flex-y(center, center);

    flex: 1 0;
    padding: 0 1rem;
    position: relative;

    & + .card {
        margin-top: 3rem;

        @include breakpoint(medium) {
            margin-top: 0;
        }
    }

    &.-flex-start {
        align-items: flex-start;
    }
}

@include breakpoint(medium) {
    .card--link:nth-child(even) {
        margin-top: 7rem;

        .card-slider-wrap {
            order: 2;
            margin-top: 2rem;
        }
    }
}

.card--link-text {
    padding-left: 3rem;
    order: 1;
    max-width: 13rem;
}

.card__svg {
    width: 100%;

    @include breakpoint(medium) {
        max-width: 40%;
    }
}

.card__content {
    max-width: 20rem;
    padding: 0 1rem;
}

.row.-flex-x:nth-child(even) .card__content {
    @include breakpoint(medium) {
        order: -1;
    }
}

.card__image {
    max-width: 100%;
}

.card__portait {
    max-width: 60%;
    margin-bottom: 0.5rem;
}

.card__text {
    width: 100%;
}

.card__link {
    @include bold;

    margin-top: 1rem;
}

// Card Slider
.card-slider-wrap {
    position: relative;
    margin-bottom: 3rem;
    width: 100%;
    max-width: 13rem;

    @include breakpoint(xlarge) {
        max-width: 20rem;
    }
}

.card-slider__shadow {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: black;
    opacity: 0.25;
    transform: translate(1.5rem, 1.5rem);
}

.card-slider {
    position: relative;
}

.card-slider__wrapper::after {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
    background-color: $black;
    opacity: 0.4;
}

.card-slider__slide {
    height: 13rem;

    @include breakpoint(xlarge) {
        height: 20rem;
    }
}

.card-slider__image {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.card-slider__label {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 60%;
    z-index: 1;
}

.card-slider__arrow {
    position: absolute;
    top: 25%;
    left: 25%;
    width: 60%;
    opacity: 0;
}