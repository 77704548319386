/* GRID */
/* Section */
.section {
    width: 100%;
    box-sizing: border-box;

    @include flex-y(center, center);

    &.-space-padding {
        padding-top: 3.5rem;
        padding-bottom: 3.5rem;
    }
    &.-space-padding-top{
        padding-top: 3.5rem;
    }

    &.-color-light {
        background-color: $light;
    }

    &.-color-white {
        background-color: $white;
    }

    &.-section-height {
        min-height: 45vw;
    }

    &:focus,
    &:active,
    &:visited {
        border: none;
        outline: 0;
    }
}

/* Container */
.container {
    width: 100%;
    max-width: 30rem;

    @include flex-y(center, center);

    box-sizing: border-box;

    @include breakpoint(large) {
        max-width: 42rem;
    }

    @include breakpoint(xlarge) {
        max-width: 52rem;
    }

    &.-space-gutter {
        padding-left: 1rem;
        padding-right: 1rem;
    }
}

/* Row */
.row {
    width: 100%;
    max-width: 100%;
    box-sizing: border-box;

    &.-max-width {
        max-width: 40rem;

        @include breakpoint(large) {
            max-width: 42rem;
        }

        @include breakpoint(xlarge) {
            max-width: 46rem;
        }
    }

    &.-max-width-small {
        max-width: 25rem;

        @include breakpoint(large) {
            max-width: 27rem;
        }

        @include breakpoint(xlarge) {
            max-width: 38rem;
        }
    }

    &.-flex-y {
        @include flex-y(center, center);
    }

    &.-flex-x {
        @include flex-y(flex-start, flex-start);

        @include breakpoint(medium) {
            @include flex-x(center, center, nowrap);
        }
    }

    &.-flex-x-start {
        @include flex-y(flex-start, flex-start);

        @include breakpoint(medium) {
            @include flex-x(center, flex-start, nowrap);
        }
    }
	
	&.-flex-x-stretch {
        @include flex-y(flex-start, stretch);

        @include breakpoint(medium) {
            @include flex-x(center, flex-start, nowrap);
        }
    }
	
    &.-flex-between {
        @include flex-y(flex-start, flex-start);

        @include breakpoint(medium) {
            @include flex-x(space-between, flex-start, nowrap);
        }
    }

    &.-space-padding {
        padding-top: 5rem;
        padding-bottom: 5rem;
    }

    &.-space-padding-top {
        padding-top: 5rem;
    }

    &.-space-padding-small {
        padding-top: 2rem;
        padding-bottom: 2rem;
    }
    &.-space-padding-verysmall {
        padding-top: 1rem;
        padding-bottom: 1rem;
    }

    &.-position-relative {
        position: relative;
    }

    &.-space-gutter {
        padding-left: 1rem;
        padding-right: 1rem;
    }

    &.-row-height {
        min-height: 50vw;
    }

    &.-align-center {
        text-align: center;
    }
	&.-display-none {
		display: none;
    }
    &.-color-white {
		background-color: $white;
	}
}

/* Column */
.column {
    width: 100%;
    max-width: 30rem;
    box-sizing: border-box;

    &.-space-padding {
        padding: 0 1rem;
    }
}