/* SETTINGS */
/* Colors */
$primary: #ffa477;
$secondary: #1d366d;
$white: #ffffff;
$light: #cbcbcb;
$middle: #72615a;
$dark: #1e1e1e;
$black: #000000;
$error: #d9534f;
$success: #5cb85c;

/* Fluid Font Size */
$min_width: 320px;
$max_width: 2500px;
$min_font: 18px;
$max_font: 28px;

/* Media Queries */
$small: 0px;
$medium: 750px;
$large: 1050px;
$xlarge: 1600px;
$xxlarge: 2000px;
$xxxlarge: 2700px;

/* Font */
$font: "sofia-pro";
