/* ANIMATION */
/* LINE */
@keyframes line {
  from {
    stroke-dashoffset: 1000;
  }

  to {
    stroke-dashoffset: 0;
  }
}

/* Glow */
@keyframes glow {
  from {
    stroke-dashoffset: 60;
  }

  to {
    stroke-dashoffset: 0;
  }
}

/* CHART */
@keyframes fillup {
  from {
    stroke-dasharray: 0 100;
  }
}
